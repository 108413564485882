import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Container } from 'react-bootstrap'

import config from '../../config.json'
import Hero from '../components/Hero/Hero'
import HowItWork from '../components/HowItWork/HowItWork'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import Opinion from '../components/Slide/Opinion'
import { useElementOnScreen } from '../hooks/useElementOnScreen'

import healthSVG from '../assets/images/icons/category/health.svg'
import lifeSVG from '../assets/images/icons/category/life.svg'
import householdSVG from '../assets/images/icons/category/household.svg'

const Slide = React.lazy(() => import('../components/Slide/Slide'))
const Ranking = React.lazy(() => import('../components/Ranking/Ranking'))

const IndexPage = () => {
  const data = useStaticQuery(query)
  const seo = data.wpPage.seo
  const opinions = data.allOpinion.edges
  const lifeRanking = data.life.edges
  const householdRanking = data.household.edges
  const healthRanking = data.health.edges
  const isSSR = typeof window === 'undefined'

  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  })

  const [slideRef, slideRefIsVisible] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  })

  const texts = {
    subheading: 'Ranking',
    heading: 'Dobrych Polis',
    paragraphs: [
      'Zaoszczędź czas, korzystając z aktualnego rankingu ubezpieczeń.<br /> Chroń życie, zdrowie i mieszkanie. Zaufało nam już 20 000 użytkowników.',
      'Nasz <strong>Ranking Dobrych Polis</strong><br /> jest wiarygodną oceną firm, które pomagają przy wyborze TU.',
    ],
  }

  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname="/" />
      <Hero
        subheading={texts.subheading}
        heading={texts.heading}
        paragraphs={texts.paragraphs}
        button={[
          {
            text: 'życiowe',
            externalLink: '/#ranking-ubezpieczen-na-zycie',
            icon: lifeSVG,
          },
          {
            text: 'mieszkaniowe',
            externalLink: '/#ranking-ubezpieczen-nieruchomosci',
            icon: householdSVG,
          },
          {
            text: 'zdrowotne',
            externalLink: '/#ranking-ubezpieczen-zdrowotnych',
            icon: healthSVG,
          },
        ]}
        img={
          <StaticImage
            src="../assets/images/home/home-bg.png"
            loading="eager"
            width={650}
            quality={90}
            placeholder="none"
            alt="Ranking dobrych polis na życie"
            title="Ranking dobrych polis na życie"
          />
        }
      />
      <Container ref={containerRef} style={{ minHeight: '300px' }}>
        {isVisible && !isSSR && (
          <React.Suspense fallback={<div className="loader"></div>}>
            <Ranking
              ranking={lifeRanking}
              heading={`Ranking ubezpieczeń życiowych ${new Date().getFullYear()}`}
              rankingButtonText="Zobacz pełny ranking ubezpieczeń na życie"
              internalLink="/ranking-ubezpieczen-na-zycie/"
              type="ranking-ubezpieczen-na-zycie"
            />
            <Ranking
              ranking={householdRanking}
              heading={`Ranking ubezpieczeń nieruchomości ${new Date().getFullYear()}`}
              rankingButtonText="Zobacz pełny ranking ubezpieczeń nieruchomości"
              internalLink="/ranking-ubezpieczen-nieruchomosci/"
              type="ranking-ubezpieczen-nieruchomosci"
            />
            <Ranking
              ranking={healthRanking}
              heading={`Ranking ubezpieczeń zdrowotnych ${new Date().getFullYear()}`}
              rankingButtonText="Zobacz pełny ranking ubezpieczeń zdrowotnych"
              internalLink="/ranking-ubezpieczen-zdrowotnych/"
              type="ranking-ubezpieczen-zdrowotnych"
            />
          </React.Suspense>
        )}
      </Container>

      <HowItWork />
      <Container ref={slideRef}>
        {slideRefIsVisible && !isSSR && (
          <React.Suspense fallback={<div className="loader"></div>}>
            <div class="opinions-slider">
              <Slide heading="Najnowsze opinie">
                {opinions.map((opinion, i) => {
                  return <Opinion {...opinion.node} key={i} commentMinHeight="155px" />
                })}
              </Slide>
            </div>
          </React.Suspense>
        )}
      </Container>
    </Layout>
  )
}

export default IndexPage

const query = graphql`
  {
    allOpinion {
      edges {
        node {
          author
          comment
          companyName
          rating
          publishedDate
          websiteKey
        }
      }
    }
    wpPage(slug: { eq: "strona-glowna" }) {
      seo {
        metaDesc
        title
        focuskw
      }
    }
    life: allRanking(
      sort: { fields: average, order: DESC }
      filter: { rankingType: { eq: "ubezpieczenia-na-zycie" } }
      limit: 3
    ) {
      edges {
        node {
          average
          company
          companyId
          id
          opinionsCount
          position
          sum
          slug
          rankingType
          formUrl
          ctaDetailsUrl
          ctaDetailsLabel
          cotillion
        }
      }
    }
    household: allRanking(
      sort: { fields: average, order: DESC }
      filter: { rankingType: { eq: "ubezpieczenia-majatkowe" } }
      limit: 3
    ) {
      edges {
        node {
          average
          company
          companyId
          id
          opinionsCount
          position
          sum
          slug
          rankingType
          formUrl
        }
      }
    }
    health: allRanking(
      sort: { fields: average, order: DESC }
      filter: { rankingType: { eq: "ubezpieczenia-zdrowotne" } }
      limit: 3
    ) {
      edges {
        node {
          average
          company
          companyId
          id
          opinionsCount
          position
          sum
          slug
          rankingType
          formUrl
        }
      }
    }
  }
`
