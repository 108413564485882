import React, { useState } from 'react'

import QuotationImg from '../../assets/images/home/quotation-marks.png'
import CalendarImg from '../../assets/images/icons/calendar.svg'
import Stars from '../Stars/Stars'
import { Calendar, Comment, Company, Container, Date, From, Name, QuotationMark, ShowMore, Wrapper } from './Opinion.style'

export default function Opinion({ author, comment, companyId, rating, publishedDate, websiteKey, isVertical, commentMinHeight}) {
  const [isOpen, setIsOpen] = useState(false)

  const commentLengthHidden = 150
  const getComment = () => {
    if (comment.length <= commentLengthHidden) return comment
    if (comment.length > commentLengthHidden && isOpen) {
      return (
        <>
          {comment}
          <ShowMore onClick={() => setIsOpen(false)}> zwiń</ShowMore>
        </>
      )
    }
    if (comment.length > commentLengthHidden) {
      return (
        <>
          {comment.slice(0, commentLengthHidden).concat('...')}
          <ShowMore onClick={() => setIsOpen(true)}> rozwiń</ShowMore>
        </>
      )
    }
  }



  const changeAuthorUnknown = (author) => {
    if (author === '_unknown') return 'nieznany'
    return author
  }
  const authorToDisplay = changeAuthorUnknown(author)

  return (
    <Wrapper vertical={isVertical} className="slider-box">
      <QuotationMark>
        <img src={QuotationImg} alt="" />
      </QuotationMark>
      <Name>{authorToDisplay}</Name>
      <From>opinia z <span>{websiteKey}</span></From>
      <Company>{companyId}</Company>
      <Comment minHeight={commentMinHeight}>{getComment()}</Comment>
      <Container>
        <div className="d-flex" style={{maxHeight: '46px'}}>
          <Calendar src={CalendarImg} />
          <Date>{publishedDate}</Date>
        </div>
        <Stars rating={rating} />
      </Container>
    </Wrapper>
  )
}
