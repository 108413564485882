import styled from 'styled-components'

import { color } from '../../assets/styles/variables'

export const Wrapper = styled.div``

export const QuotationMark = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 7px;
  background-color: #053592;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: -30px;
  img {
    width: 20px;
  }
`
export const Name = styled.p`
  color: #053592;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 30px;
  line-height: 1.2;
`
export const From = styled.p`
  font-size: 16px;
  color: #99a5bb;
  margin-top: 0;
  margin-bottom: 0;
  span{
    text-transform: capitalize;
  }
`
export const Company = styled.p`
  color: #053592;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 17px;
`

export const ShowMore = styled.a`
text-decoration: none;
color: ${color.primary};
cursor: pointer;
&:hover{
  color: ${color.linkHover}
}
`

export const Comment = styled.p`
  font-size: 16px;
  color: #2e3d59;
  font-weight: 500;
  margin-top: 10px;
  min-height: ${props => props.minHeight ? props.minHeight : "auto"};
  transition: all 0.3s ease;
`

export const Container = styled.div`
  position: absolute;
  bottom: 25px;
  left: 50px;
  @media screen and (max-width: 991px) {
    left: 20px;
  }
`

export const Date = styled.p`
  color: #053592;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
  display: inline-block;
`
export const Calendar = styled.img`
  width: 18px;
  display: inline-block;
  margin-right: 8px;
  margin-top:6px;
`
